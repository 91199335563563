



































import Vue from "vue";
export default Vue.extend({
  data: function () {
    return {
      customValues: "",
    };
  },
  mounted: function () {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
    this.$store.dispatch("SetLogoBottomStyle", { color: "light" });
  },
  methods: {
    UpdateCustomCoreValues: function () {
      const values: string[] = this.customValues.split(",");
      for (let i = 0; i < values.length; i++) {
        this.$store.dispatch("AddCustomCoreValues", { value: values[i] });
      }
      this.$router.push(`/5`);
    },
  },
});
